<template>
    <vx-card title="Canvas - Item Request">
        <div class="vx-input-group flex">
            <vs-tabs v-if="render" v-model="$route.params.status">
                <vs-tab label="New">
                    <tab-new></tab-new>
                </vs-tab>
                <vs-tab label="Waiting">
                    <tab-waiting></tab-waiting>
                </vs-tab>
                <vs-tab label="Inquiry">
                    <tab-inquiry></tab-inquiry>
                </vs-tab>
                <vs-tab label="Load-Confirm">
                    <tab-load-confirm></tab-load-confirm>
                </vs-tab>
                <vs-tab label="Rejected">
                    <tab-rejected></tab-rejected>
                </vs-tab>
                <vs-tab label="Pending">
                    <tab-pending></tab-pending>
                </vs-tab>
                <vs-tab label="Hand-overed">
                    <tab-handovered></tab-handovered>
                </vs-tab>
                <vs-tab label="Canceled">
                    <tab-canceled></tab-canceled>
                </vs-tab>
                <vs-tab label="Unloading">
                    <tab-unloading></tab-unloading>
                </vs-tab>
                <vs-tab label="Close">
                    <tab-close></tab-close>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>

<script>
import TabNew from "./TabNew.vue";
import TabWaiting from "./TabWaiting.vue";
import TabInquiry from "./TabInquiry.vue";
import TabLoadConfirm from "./TabLoadConfirm.vue";
import TabRejected from "./TabRejected.vue";
import TabPending from "./TabPending.vue";
import TabHandovered from "./TabHandovered.vue";
import TabCanceled from "./TabCanceled";
import TabUnloading from "./TabUnloading";
import TabClose from "./TabClose";

export default{
    components: {
        TabNew,
        TabWaiting,
        TabInquiry,
        TabLoadConfirm,
        TabRejected,
        TabPending,
        TabHandovered,
        TabCanceled,
        TabUnloading,
        TabClose,
    },
    data() {
        return {
            activeTab: "New",
            tabStatus: "",
            render: true,
        }
    },
    methods: {}
}
</script>